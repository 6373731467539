import * as Yup from 'yup';

const alpha = /^[a-zA-Z_]+( [a-zA-Z_]+)*$/;

const validation = Yup.object().shape({
  fullName: Yup.string()
    .matches(alpha, {message: "Enter Valid Name", excludeEmptyString: true })
    .required(),
    email: Yup.string()
    .required(),
    number: Yup.string()
    .required(),
  terms: Yup.string()
    .test('terms', 'you must agree to terms', value => value !== false)
    .required('required'),
});

export default validation;
