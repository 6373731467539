import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  /**
   * One more button variant combinations
   * buttons may be one of a varietny of visual variants such as:
   * 'primary', 'secondary', 'success', 'danger', 'link
   */
  variant: PropTypes.string,

  /**
   * Specifies a large or small
   *
   * @type ('sm'|'lg')
   */
  size: PropTypes.string,

  /** Set the visual state of the button to :active */
  active: PropTypes.bool,

  /**
   * Disables the Button, preventing mouse events,
   * even if the underlying component in an `<a>` element
   */
  disabled: PropTypes.bool,

  /** Provigin a `href` will render an `<a>` element, __styled__ as a button */
  href: PropTypes.string,

  /**
   * Defines HTML button type attribute
   *
   * @default 'button
   */
  type: PropTypes.oneOf(['button', 'reset', 'submit', null]),

  as: PropTypes.elementType,
};

const defaultProps = {
  variant: 'primary',
  active: false,
  disabled: false,
  type: 'button',
};

const StyledButton = styled.button`
  -webkit-appearance: none;
  background: ${props => props.theme.button.colors.primary.background};
  border: none;
  border-radius: 0;
  font-size: ${props => (props.big ? '1.2rem' : '1rem')};
  color: ${props => props.theme.button.colors.primary.color};
  padding: ${props => (props.big ? '0.5rem 1.75rem' : '0.35rem 1.65rem')};
  transition: all ${props => props.theme.transitions.normal};
  // box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  &:hover {
    background: ${props => props.theme.button.colors.primary.backgroundHover};
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

const Button = ({ variant, size, active, className, type, as, ...props }) => {
  if (props.href) {
    return <a href={props.href} />;
  }

  const prefix = 'btn';

  const classes = classNames(
    className,
    prefix,
    active && `${prefix}-active`,
    `${prefix}-${variant}`,
    size && `${prefix}-${size}`
  );
  const Component = as || StyledButton;

  return <Component {...props} type={type} className={classes} />;
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
