import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';

import Layout from '../components/layout';
import SEO from '../components/seo';

import DynamicForm from '../components/Forms/DynamicForm';
import validation from '../components/Forms/validation';

import { Container, Row, Section } from '../components/Grid';

import Hero from '../components/hero';

import JoinImage from '../images/join.jpg';
import SellImage from '../images/sell.png';
import Header from '../components/header';

const fields = [
  { label: 'Full Name', type: 'input', name: 'fullName', value: '' },
  { label: 'Email address', type: 'input', name: 'email', value: '' },
  { label: 'Contact number', type: 'input', name: 'number', value: '' },
  { label: 'Location', type: 'input', name: 'location', value: '' },
  {
    label: 'How many investment properties do you currently have?',
    type: 'select',
    data: ['1-4', '5-9', '10+'],
    name: 'properties',
    value: 'Please Select',
  },
  {
    label: 'Do you currently have a pre-approval or are you cash ready?',
    type: 'select',
    data: ['I have pre-approval', 'I am cash ready', "I'm not sure"],
    name: 'preapproval',
    value: 'Please Select',
  },
  {
    label: 'What is the total gross income for the borrowers?',
    type: 'select',
    data: [
      '$100,000 - $200,000',
      '$200,000 - $300,000',
      '$400,000 - $500,000',
      '$500,000+',
    ],
    name: 'grossIncome',
    value: 'Please Select',
  },
  {
    label: 'Are you looking for existing or new property?',
    type: 'select',
    data: ['Existing property', 'New property', "I'm not sure"],
    name: 'propertyAge',
    value: 'Please Select',
  },
  {
    label: 'What type of property are you looking for?',
    type: 'select',
    data: ['Residential', 'Commercial', "I'm not sure"],
    name: 'typeProperty',
    value: 'Please Select',
  },
  {
    label:
      'By signing up you agree to the terms and conditions and agree that all information above is accurate to the best of your knowledge',
    type: 'checkbox',
    name: 'terms',
    value: false,
  },
];

const ContentSection = styled(Section)`
  margin-bottom: 225px;
  position: relative;

  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 170px;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const TextColumn = styled.div`
  p {
    font-size: 18px;
    line-height: 22px;
    color: ${props => props.theme.colors.grey.lighter};

    @media (min-width: ${props => props.theme.screen.md}) {
      color: ${props => props.theme.colors.grey.darker};
    }
  }

  max-width: 28rem;
  margin-bottom: 2rem;
  margin-top: 1rem;

  @media (min-width: ${props => props.theme.screen.md}) {
    text-align: right;
    margin-left: auto;
    margin-right: auto;
    align-self: flex-end;
    margin-top: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const TextLeftColumn = styled(TextColumn)`
  p {
    text-align: left;
  }

  bottom: 0;
`;

const SectionHeadingRight = styled.h3`
  font-size: 40px;
  line-height: 1.1;
  max-width: 24rem;
  position: relative;
  z-index: 1;

  @media (min-width: ${props => props.theme.screen.md}) {
    font-size: 50px;
    line-height: 70px;
    text-align: right;
    top: 225px;
    margin: 0 auto;
  }
`;

const SectionHeadingLeft = styled(SectionHeadingRight)`
  @media (min-width: ${props => props.theme.screen.md}) {
    text-align: left;
    top: 150px;
  }
`;

const ContentImage = styled.div`
  display: flex;
  object-fit: cover;
  position: relative;
  z-index: -1;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 55%;
    position: absolute;
    bottom: 0;
    right: 50%;
    z-index: -1;
    height: 75%;
    opacity: 0.3;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  .react-reveal {
    opacity: 1 !important;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #161618;
  transition: right 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 1s;
  right: 0;

  &.wipe-img {
    right: 100%;
    transition: right 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-delay: 1s;
    left: auto;
  }

  &.wipe-img-left {
    left: 100%;
    transition: left 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-delay: 1s;
    right: auto;
  }
`;

function RevealFirstImage() {
  if (typeof document !== 'undefined') {
    const overlay = document.querySelector('#overlay');
    overlay.classList.add('wipe-img');
  }
}

function RevealSecondImage() {
  if (typeof document !== 'undefined') {
    const overlay2 = document.querySelector('#overlay2');
    overlay2.classList.add('wipe-img-left');
  }
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" description="The exclusive property buyers club. Receive investment deals direct before anyone else." />
    <div>
      <Header />
      <Hero />
      <Container>
        <ContentSection>
          <Fade cascade right exit={false}>
            <SectionHeadingRight>
              What&apos;s holding you back?
            </SectionHeadingRight>
          </Fade>
          <Row>
            <TextColumn>
              <Fade left exit={false}>
                <p>
                  You’ve learnt that to find the best deals it takes time,
                  negotiation skills, being in the right place at the right time
                  and most importantly, a readiness to buy.
                </p>
                <p>
                  Through relentless effort, marketing and contacts, we
                  specifically hunt for only the best deals and present them to
                  our clients. ripe for the picking.
                </p>
                <p>
                  We don’t think simply emailing a database is appropriate in
                  this active market, so once you’re a member of
                  Buyersclub.co.nz you’ll be given a phone call first with every
                  property that is offered to you, and if you’re genuinely
                  interested then we follow this up with our value pack, which
                  is our property specific information pack on the potential of
                  your new purchase.
                </p>
              </Fade>
            </TextColumn>
            <ContentImage>
              <img src={JoinImage} alt="Join buyersclub" />
              <Overlay id="overlay">
                <Reveal onReveal={() => RevealFirstImage()} />
              </Overlay>
            </ContentImage>
          </Row>
        </ContentSection>
        <DynamicForm name="join" fields={fields} validation={validation} />

        <ContentSection>
          <Fade cascade left exit={false}>
            <SectionHeadingLeft>Looking to sell?</SectionHeadingLeft>
          </Fade>
          <Row>
            <ContentImage>
              <img src={SellImage} alt="Looking to sell?" />
              <Overlay id="overlay2">
                <Reveal onReveal={() => RevealSecondImage()} />
              </Overlay>
            </ContentImage>
            <TextLeftColumn>
              <Fade right exit={false}>
                <p>
                  You’re looking to sell your property and want a cash buyer for
                  immediate sale or to flexible terms?
                  <br />
                  You’re in the right place.
                </p>

                <p>
                  Prices are agreed and negotiated, and we take pride in holding
                  a great reputation with vendors to achieve a happy result for
                  all parties.
                </p>
                <p>
                  Email us at{' '}
                  <a href="mailto:contact@buyersclub.co.nz">
                    contact@buyersclub.co.nz
                  </a>{' '}
                  with your contact details and property address and we will get
                  in touch immediately.
                </p>
              </Fade>
            </TextLeftColumn>
          </Row>
        </ContentSection>
      </Container>
    </div>
  </Layout>
);

export default IndexPage;
