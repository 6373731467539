import React, { Component, Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import styled, { css } from 'styled-components';
import axios from 'axios';
import Button from '../button';
import Arrow from '../../images/arrow-down.svg';

const StyledInput = styled.input`
  border: 1px solid #979797;
  background: transparent;
  width: 100%;
  opacity: 0.7;
  font-size: 18px;
  color: #e8f5e9;
  padding: 14px 29px;
  margin-bottom: 1em;
  border-radius: 0;

  &.hasError {
    border-color: red;
    color: red;
  }

  &::placeholder {
    color: #e8f5e9;
    opacity: 1;
  }
`;

const StyledSelect = styled.select`
  border: 1px solid #979797;
  width: 100%;
  opacity: 0.7;
  font-size: 18px;
  color: #e8f5e9;
  padding: 14px 29px;
  margin-bottom: 1em;
  -webkit-appearance: none;
  border-radius: 0;
  position: relative;
  background: url(${Arrow}) 98% / 2% no-repeat;
  background-color: #161618;
  -ms-appearance: none;

  &.hasError {
    border-color: red;
    color: red;
  }

  &::-ms-expand {
    display: none;
  }

  option {
    opacity: 0.7;
  }
`;

const ButtonWrapper = styled.div`
  button {
    width: 100%;
    text-align: center;
    font-size: 18px;
    padding-top: 16px;
    padding-bottom: 14px;

    &:hover {
      background-color: #979797;
      color: white;
      transition: all 0.25s ease-in-out;
    }
  }
`;

const CheckboxWrapper = styled.div`
  label {
    font-size: 15px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.3);
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 40px;
    margin-top: 20px;

    .checkmark {
      position: absolute;
      top: 4px;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 1px solid #979797;

      &::after {
        content: '';
        position: absolute;
        display: none;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark::after {
        display: block;
        left: 6px;
        top: 0;
        width: 7px;
        height: 15px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
`;

const encode = data => {
  return (
    Object.keys(data)
      // eslint-disable-next-line max-len
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&')
  );
};

class DynamicForm extends Component {
  renderCheckBox(input) {
    return (
      <Fragment key={input.name}>
        <Field
          name={input.name}
          render={(prop) => {
            const { field } = prop;
            return (
              <CheckboxWrapper>
                <label>
                  {input.label}
                  <input
                    name={input.name}
                    type="checkbox"
                    checked={field.value}
                    onChange={field.onChange}
                    css={css`margin-right: 10px;`}
                  />
                  <span className="checkmark" />
                </label>
              </CheckboxWrapper>
            );
          }}
        />
      </Fragment>
    );
  }

  renderTextArea(input) {
    return (
      <Fragment key={input.name}>
        <div>
          <label>{input.label}</label>
          <div>
            <Field
              name={input.name}
              render={(props) => {
                const { field } = props;
                const { errors, touched } = props.form;
                const hasError = errors[input.name] && touched[input.name] ? 'hasError' : '';
                return (
                  <div>
                    <textarea {...field} id={hasError} />
                  </div>
                );
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  renderSelect(input) {
    return (
      <Fragment key={input.name}>
        <div>
          <label className="hide">{input.label}</label>
          <div>
            <Field
              name={input.name}
              render={(props) => {
                const { field } = props;
                const { errors, touched } = props.form;
                const hasError = errors[input.name] && touched[input.name] ? 'hasError' : '';
                const defaultOption = <option key='default' value=''>{input.label}</option>;
                const options = input.data.map(i => <option key={i} value={i}> {i} </option> );
                const selectOptions = [defaultOption, ...options];
                return (
                  <div className="dropdown">
                    <StyledSelect className={hasError} value={field.value} {...field} id={hasError}>
                      {
                        selectOptions
                      }
                    </StyledSelect>
                  </div>
                );
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  renderFields(inputs) {
    return inputs.map(input => {
      if (input.type === 'select') {
        return this.renderSelect(input);
      }
      if (input.type === 'checkbox') {
        return this.renderCheckBox(input);
      }
      if (input.type === 'textarea') {
        return this.renderTextArea(input);
      }
      return (
        <div key={input.name}>
          <label className="hide">{input.label}</label>
          <div>
            <Field
              name={input.name}
              render={props => {
                const { field } = props;
                const { errors, touched } = props.form;
                const hasError = errors[input.name] && touched[input.name] ? 'hasError' : '';
                return <StyledInput className={hasError} {...field} id={hasError} type="text" placeholder={input.label} />;
              }}
            />
          </div>
        </div>
      );
    });
  }

  getInitialValues(inputs) {
    // declare an empty initialValues object
    const initialValues = {};
    // loop loop over fields array
    // if prop does not exit in the initialValues object,
    // pluck off the name and value props and add it to the initialValues object;
    inputs.forEach(field => {
      if (!initialValues[field.name]) {
        initialValues[field.name] = field.value;
      }
    });
    // return initialValues object
    return initialValues;
  }

  async handleSubmit(values, actions) {
    const url = '/';

    const axiosConfig = {
      header: { 'Content-Type': 'application/x-www-form-urlencoded' },
    };

    try {
      await axios
        .post(
          '/',
          encode({ 'form-name': `${this.props.name}`, ...values }),
          axiosConfig
        )
        .then(() => {
          this.setState({ submitted: true, loading: false });
          // if (actions && typeof actions.setSubmitting === 'function') {
          //   actions.setSubmitting(false);
          // }
          document.querySelector('.form-success').classList.add('db');
          document.querySelector('.formWrapper').classList.add('hide');
        })
        .catch(e => {
          console.warn(e);
          this.setState({ submitted: false, loading: false });
        });
    } catch (e) {
      throw new Error(e.message);
    }
  }

  render() {
    const initialValues = this.getInitialValues(this.props.fields);
    return (
      <div className="app" id="join">
        <div className="formWrapper">
          <h3 className="f1 align-center my8">Join the club</h3>
          <Formik
            onSubmit={values => this.handleSubmit(values)}
            validationSchema={this.props.validation}
            initialValues={initialValues}
            render={(form) => {
              const errorMessageShow = Object.keys(form.errors).length > 0 ? 'error' : 'hidden';
              return (
                <Form
                  id={this.props.name}
                  name={this.props.name}
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  autoComplete="off"
                  css={css`
                    max-width: 32em;
                    margin: 0 auto;
                    margin-bottom: 71px;

                    @media (min-width: ${props => props.theme.screen.md}) {
                      margin-bottom: 200px;
                    }
                  `}
                >
                  {this.renderFields(this.props.fields)}
                  <div className={errorMessageShow} css={css`margin-top: 2rem; color: red;`}>
                    Please correct the errors above
                  </div>
                  <ButtonWrapper>
                    <Button big type="submit" css={css`margin-top: 2rem;`}>Register</Button>
                  </ButtonWrapper>
                </Form>
              );
            }}
          />
        </div>
        <div
          className="form-success"
          css={css`
            display: none;
            padding: 20px;
            background: ${props => props.theme.colors.primary};
            color: white;
            font-size: 18px;
            font-weight: 700;
            border-radius: 3px;
            text-align: center;

            &.db {
              display: block;
            }
          `}
        >
          Thanks for your interest in joining the club! We'll be in touch with you soon.
        </div>
      </div>
    );
  }
}
export default DynamicForm;
