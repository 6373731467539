import React from 'react';
import styled from 'styled-components';
import SplitText from 'react-pose-text';
import Tilt from 'react-tilt';

import Container from './Grid/container';
import Image from '../images/hero.jpg';
import Mouse from '../images/mouse.svg';

const charPoses = {
  exit: { opacity: 0, y: 20, x: -5 },
  enter: {
    opacity: 1,
    y: 0,
    x: 0,
    delay: ({ charIndex }) => charIndex * 30,
  },
};

const HeadingWrapper = styled.div`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  h1 {
    line-height: 50px;
    font-size: 32px;

    @media (min-width: ${props => props.theme.screen.md}) {
      line-height: 65px;
      font-size: 60px;
    }
  }
`;

const Hero = styled.div`
  background: ${props => props.theme.colors.primary};
  background-image: linear-gradient(
      180deg,
      rgba(24, 24, 24, 0.9) 0%,
      rgba(24, 24, 24, 0.9) 64%,
      #161618 100%
    ),
    url(${Image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  color: ${props => props.theme.colors.white};
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
`;

const ScrollIndicator = styled.div`
  position: absolute;
  height: 69px;
  width: 100%;
  bottom: 20px;

  @media (min-width: ${props => props.theme.screen.md}) {
    bottom: -20px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    background-color: #979797;
    height: 69px;
    width: 1px;
  }
`;

const MouseIcon = styled.div`
  background-image: url(${Mouse});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  bottom: 109px;
  height: 30px;
  width: 20px;
  animation: pulse 2s ease 0s infinite;

  @media (min-width: ${props => props.theme.screen.md}) {
    bottom: 79px;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
      transform: scale(1);
      transform: translateY(0);
    }

    80% {
      opacity: 0;
      transform: scale(1.25);
      transform: translateY(10px);
    }

    100% {
      opacity: 0;
      transform: scale(1.5);
      transform: translateY(20px);
    }
  }
`;

export default () => {
  return (
    <Hero className="hero">
      <Container>
        <HeadingWrapper>
          <Tilt
            className="Tilt"
            options={{
              max: 8,
              scale: 1.01,
              speed: 500,
            }}
          >
            <div className="Tilt-inner">
              <h1>
                <SplitText
                  initialPose="exit"
                  pose="enter"
                  charPoses={charPoses}
                >
                  The exclusive property buyers club
                </SplitText>
              </h1>
            </div>
          </Tilt>
        </HeadingWrapper>
      </Container>
      <ScrollIndicator />
      <MouseIcon />
    </Hero>
  );
};
